<template>
	<div class="home-container">
		<div class="top-content">
			<div class="address-item">
				Elephant Gold Alliance
			</div>
		</div>
		<div class="info">
			<div class="info-total">
				<div class="info-total-item">
					<div class="item top">
						<div class="topItem">
							<div class="title">{{ $t('add16') }}</div>
							<div class="num">{{ totalInfo.pool_all }}<span class="symple"> EGA</span></div>
						</div>
						<div class="topItem">
							<div class="title">{{ $t('add7') }}</div>
							<div class="num">{{ numFormatter(totalInfo.income_all || 0) }}<span class="symple">
									EGA</span></div>
						</div>
					</div>
					<div class="item border top">
						<div class="topItem">
							<div class="title">{{ $t('total') }}</div>
							<div class="num">{{ numFormatter(totalInfo.pool_num || 0) }}<span class="symple"> EGA</span>
							</div>
						</div>
						<div class="topItem">
							<div class="title">{{ $t('add6') }}</div>
							<div class="num">{{ numFormatter(balance.destroy || 0) }}<span class="symple"> EGA</span>
							</div>
						</div>
					</div>
					<div class="item border top">
						<div class="topItem">
							<div class="title">{{ $t('add8') }}</div>
							<div class="num">{{ numFormatter(totalInfo.subscribe || 0) }}<span class="symple">
									EGA</span></div>
						</div>
						<div class="topItem">
							<div class="title">{{ $t('total1') }}<span class="value">1EGA≈{{ totalInfo.price }}BNB</span>
							</div>
							<div class="num">{{ numFormatter(balance.show || 0) }}<span class="symple">EGA</span><span
									class="value">≈{{ numFormatter((balance.show * totalInfo.price).toFixed(3))
									}}BNB</span></div>
						</div>
					</div>
				</div>
				<div class="info-total-item other">
					<!-- <div class="item">
						<div class="title">{{ $t('add') }}</div>
						<div class="num">{{ numFormatter(totalInfo.principal || 0) }}<span class="symple"> EGA</span></div>
						<div class="btn" @click="submitCleam1()">{{$t('withraw')}}</div>
					</div> -->
					<div class="item">
						<div class="title">{{ $t('jiedian') }}</div>
						<div class="num">{{ numFormatter(totalInfo.node_income || 0) }}<span class="symple"> EGA</span>
						</div>
						<div class="btn" @click="teamOut(2)">{{ $t('withraw') }}</div>
					</div>
					<div class="item">
						<div class="title">
							<div class="name">{{ $t('ketiqu') }}</div>
							<popover v-model="showPopover" trigger="click" theme="dark" placement="bottom-end">
								<div class="popover-content">
									<div class="popover-title">
										<div class="name">{{ $t('allIncome') }}</div>
										<div>{{ numFormatter(totalInfo.all_income) }}</div>
									</div>
									<div class="popover-title">
										<div class="name">{{ $t('total2') }}</div>
										<div>{{ numFormatter(totalInfo.dynamic_income) }}</div>
									</div>
									<div class="popover-title">
										<div class="name">{{ $t('jingtai') }}</div>
										<div>{{ numFormatter(totalInfo.statice_income) }}</div>
									</div>
								</div>
								<template #reference>
									<van-icon name="more-o" />
								</template>
							</popover>
						</div>
						<div class="num">{{ numFormatter(totalInfo.extract_income || 0) }}<span class="symple">
								EGA</span></div>
						<div class="btn" @click="teamOut(1)">{{ $t('withraw') }}</div>
					</div>
				</div>
			</div>
			<div class="info-pool">
				<div class="info-pool-title">
					<div class="name">
						<img src="@/image/icon.png" alt="">
						<span>{{$t('newBuy1')}}</span>
					</div>
				</div>
				<!-- 当前金额，下次释放时间，下次释放数量 -->
				<div class="tips">{{$t('newBuy2')}} <div class="nums">{{totalInfo.rich_num}}</div></div>
				<!-- <div class="tips">{{$t('newBuy3')}} <div class="nums">1</div></div>
				<div class="tips">{{$t('newBuy4')}} <div class="nums">1</div></div> -->
				<div class="info-pool-submit" @click="showPopupOther">{{$t('newBuy5')}}</div>
			</div>
			<div class="info-team">
				<div class="btn">
					<div class="copy" id="copy" :data-clipboard-text="url" @click="handleCopy">{{ $t('copyLink') }}</div>
				</div>
				<div class="btn">{{ $t('up') }}{{ hideAddress(teamInfo.paddress) }}</div>
				<div>{{ $t('team') }}: <span class="coin">{{ numFormatter(teamInfo.performance) }} EGA</span></div>
				<div class="num">{{ $t('team1') }}<span class="value">{{ numFormatter(teamInfo.people)
						}}</span>{{ $t('team2') }}
					{{ $t('team3') }}<span class="value">{{ numFormatter(teamInfo.push) }}</span>{{ $t('team2') }}
					{{ $t('add9') }}<span class="value">{{ numFormatter(teamInfo.push_effective) }}</span>{{ $t('team2') }}
				</div>
				<img src="@/image/team.png" alt="" class="img">
			</div>
			<div class="info-title">POOLS</div>
			<div class="info-pool">
				<div class="info-pool-title">
					<div class="name">
						<img src="@/image/icon.png" alt="">
						<span>{{ $t('title') }} POOL</span>
					</div>
				</div>
				<div class="info-pool-submit notUse" @click="showPopup1">{{ $t('buy') }}</div>
			</div>
			<div class="info-title1">MY POOLS</div>
			<div class="info-list">
				<div class="info-list-tab">
					<div class="info-list-tab-item" v-for="(item, index) in listTab" :key="index"
						:class="index == tabActive ? 'active' : ''" @click="changeTab(index)">{{ item }}</div>
				</div>
				<div v-if="tabActive == 0">
					<div class="info-list-item">
						<div class="info-list-item-info">
							<div class="infos" v-if="list">
								<!-- <div class="infos-item">
									<div class="title">{{$t('add13')}}</div>
									<div class="num">{{ numFormatter(list.rate) }}% </div>
								</div> -->
								<div class="infos-item">
									<div class="title">{{ $t('buyed') }}</div>
									<div class="num">{{ numFormatter(list.ega) }} EGA({{ list.amount }} BNB)</div>
								</div>
								<div class="infos-item">
									<div class="title">{{ $t('income') }}</div>
									<div class="num">{{ numFormatter(list.income) }} BNB</div>
								</div>
								<div class="infos-item">
									<div class="title">{{ $t('add17') }}</div>
									<div class="num">{{ numFormatter(list.extracted) }} BNB({{ list.extracted_ega }} EGA)
									</div>
								</div>
								<div class="infos-item">
									<div class="title">{{ $t('add18') }}</div>
									<div class="num">{{ list.date || '' }}</div>
								</div>


							</div>
						</div>
					</div>
				</div>
				<div v-if="tabActive == 1">
					<div class="info-list-item" v-for="(item, index) in list" :key="index">
						<div class="info-list-item-title other">
							<span class="time">{{ timestampToTime(item.date) }}</span>
						</div>
						<div class="info-list-item-info">
							<div class="infos">
								<div class="infos-item">
									<div class="title">{{ $t('buyed') }}</div>
									<div class="num">{{ numFormatter(item.ega) }} EGA({{ numFormatter(item.amount) }} BNB)
									</div>
								</div>
								<div class="infos-item">
									<div class="title">{{ $t('add15') }}</div>
									<div class="num">{{ numFormatter(item.price) }} BNB</div>
								</div>
							</div>
						</div>
					</div>
					<div class="no-data" v-if="list.length == 0">
						<img src="@/image/nodata.png" alt="">
						<span>{{ $t('nodata') }}</span>
					</div>
					<div v-show="page.total > page.pageSize">
						<pagination v-model="page.currentPage" :total-items="page.total" :items-per-page="page.pageSize"
							mode="simple" @change="pageChange()" />
					</div>
				</div>
			</div>
		</div>
		<popup v-model="popupShow1" :overlay="true" position="bottom" closeable @close="pledgeNum = ''">
			<div class="popup-content">
				<div class="popup-content-name">
					<img src="@/image/icon.png" alt="">
					<span>{{ $t('title') }} POOL</span>
				</div>
				<div class="popup-content-title other">
					<span>{{ $t('buynum') }}</span>
					<span>{{ pledgeNum ? (totalInfo.price ? ((pledgeNum / totalInfo.price) + 1).toFixed(3) : 0) : 0 }} EGA</span>
				</div>
				<div class="popup-content-inputs">
					<input type="number" :placeholder="$t('input')" v-model="pledgeNum">
					<div class="symple">BNB</div>
					<!-- <div class="allin" @click="allIn">{{$t('max')}}</div> -->
				</div>
				<div class="popup-content-balance other">
					<div>{{ $t('balance') }}: <span class="num">{{ balance.show }}</span> EGA</div>
					<div>{{ $t('allIncome') }}: <span class="num">{{ numFormatter(totalInfo.extract_income) }}</span> EGA
					</div>
				</div>
				<div class="popup-content-submits">
					<div class="btn" @click="submitBuy">{{ $t('submit') }}</div>
					<div class="btn active" @click="submitOpen()">{{ $t('add1') }}</div>
				</div>
			</div>
		</popup>
		<popup v-model="popupShowOther" :overlay="true" position="bottom" closeable @close="pledgeNum = ''">
			<div class="popup-content">
				<div class="popup-content-name">
					<img src="@/image/icon.png" alt="">
					<span>{{$t('newBuy1')}}</span>
				</div>
				<div class="popup-content-title other">
					<span>{{$t('newBuy6')}}</span>
				</div>
				<div class="popup-content-inputs">
					<input type="number" :placeholder="$t('input')" v-model="pledgeNum">
					<div class="symple">EGA</div>
				</div>
				<div class="popup-content-balance other">
					<div>{{ $t('balance') }}: <span class="num">{{ balance.show || 0 }}</span> EGA</div>
				</div>
				<div class="popup-content-submits">
					<div class="btnBig" @click="submitBuyOther">{{ $t('confirm') }}</div>
				</div>
			</div>
		</popup>
		<popup v-model="outShow" :overlay="true" position="bottom" closeable @close="pledgeNum = ''">
			<div class="popup-content">
				<div class="popup-content-name">
					<img src="@/image/icon.png" alt="">
					<span>{{ $t('withraw') }}</span>
				</div>
				<div class="popup-content-title other" v-if="selectType == 1">
					<span>{{ $t('buynum') }}</span>
					<!-- <span>{{ pledgeNum ? (totalInfo.price ? ((pledgeNum / totalInfo.price) + 1).toFixed(3) : 0) : 0 }} EGA</span> -->
				</div>
				<div class="popup-content-inputs">
					<input type="number" :placeholder="$t('input')" v-model="pledgeNum">
					<div class="symple">EGA</div>
					<div class="allin" @click="allIn1">{{ $t('max') }}</div>
				</div>
				<div class="popup-content-balance other">
					<div>{{ $t('can') }}:<span class="num">{{ selectType == 1 ? totalInfo.extract_income :
					totalInfo.node_income }}</span><span v-if="selectType == 1"></span>EGA≈{{
					(totalInfo.extract_income * totalInfo.price - 0.001).toFixed(3) }}BNB</div>
					<!-- <div v-if="selectType == 1">{{ $t('everyDay') }}:<span class="num">{{ totalInfo.now_num }}</span> BNB
					</div> -->
				</div>
				<!-- <div class="popup-content-balance other" v-if="selectType == 1">
					<div>{{ $t('todayNum') }}:<span class="num">{{ totalInfo.today_num }}</span> BNB</div>
					<div>{{ $t('minnum') }}:<span class="num">{{ totalInfo.min_num }}</span> BNB</div>
				</div> -->
				<div class="popup-content-submit" @click="submitTeamOut">{{ $t('sure') }}</div>
			</div>
		</popup>
		<popup v-model="bindShow" :overlay="true" position="bottom" :close-on-click-overlay="false"
			@close="upAddress = ''">
			<div class="popup-bind">
				<div class="popup-bind-title">{{ $t('bindup') }}</div>
				<div class="popup-bind-input">
					<input type="text" v-model="upAddress" :placeholder="$t('bindup')">
				</div>
				<div class="popup-bind-btn flex align-items-center justify-content-center" @click="submitBind()">
					{{$t('confirm')}}</div>
			</div>
		</popup>
	</div>
</template>

<script>
var _this;
import Web3 from '@/utils/web3.js'
import Clipboard from 'clipboard';
import { Popup, Pagination, Popover } from 'vant';
import http from '@/utils/http.js'
import BigNumber from "bignumber.js";
export default {
	name: "home",
	components: {
		Popup,
		Pagination,
		Popover
	},
	data() {
		return {
			showPopover: false,
			oneShow: false,
			oneOut: false,
			outShow: false,
			selectType: 1,
			bindShow: true,
			upAddress: '',
			popupShow1: false,
			popupShowOther: false,
			pledgeNum: '',
			produceNum: '',
			web3js: '',
			address: '',
			oneInfo: {
				rate: 0,
				day: 0,
				price: 0,
				isbuy: false,
				expected: 0,
				income: 0,
				is_close: false
			},
			totalInfo: {
				subscribe: 0,
				ega: 0,
				price: 0,
				dynamic: 0,
				all_income: 0,
				extract_income: 0,
				dynamic_income: 0,
				statice_income: 0,
				node_income: 0,
				rate: 0,
				today_num: 0,
				now_num: 0,
				min_num: 0,
			},
			ega_num: 0,
			poolInfo: {
				min_num: 0,
				min_rate: 0,
				max_rate: 0,
				countdown_mining: 0
			},
			teamInfo: {
				people: 0,
				performance: 0,
				push: 0,
				paddress: '',
				push_effective: 0
			},
			page: {
				currentPage: 1,
				pageSize: 5,
				total: 0
			},
			list: [],
			listTab: ['我的矿池', '认购记录'],
			tabActive: 0,
			nowtime: 0,
			listChoose: 0,
			balance: {
				show: '',
				real: 0,
				destroy: ''
			},
			poolSelect: null,
			poolContract: '0x47ee444f1F4DE117D62BD44EC7bAD1DBA08A23Ce',
			egaContract: '0xD151e5F58B6dAd0EC955e7148735C96F4e3B2ffa',
			pool_instance: '',
			egaSwap_instance: '',
			timer: null,
			timeStr: ''
		}
	},
	mounted() {
		this.init();
		if (this.$route.query.p) {
			this.upAddress = this.$route.query.p;
		}
		new Clipboard('#copy');
		this.listTab = [this.$t('add19'), this.$t('add20')];
	},
	beforeCreate() {
		_this = this;
	},

	beforeDestroy() {
		if (this.timer) {
			clearInterval(this.timer);
		}
	},
	computed: {
		url() {
			return `${window.location.origin}/#/?p=${this.address}`;
		},
	},
	watch: {
		'$i18n.locale'(newVal, oldVal) {
			this.listTab = [this.$t('add19'), this.$t('add20')];
		}
	},
	methods: {
		showPopup1() {
			return;
			// this.popupShow1 = true;
		},
		showPopupOther() {
			this.popupShowOther = true;
		},
		oneWithraw() {
			if (this.oneInfo.isbuy && this.oneInfo.is_close) return false
			this.oneOut = true
		},
		teamOut(type) {
			if ((type == 1 && this.totalInfo.extract_income == 0) || (type == 2 && this.totalInfo.node_income == 0)) return false;
			this.selectType = type;
			this.outShow = true;
		},
		pageChange() {
			this.getList();
		},
		init() {
			Web3.getWeb3().then(async res => {
				this.web3js = res;
				try {
					const result = await window.ethereum.enable();
					this.address = result[0];
					this.$store.commit("setAddress", this.address);
					this.getAccount();
				} catch (error) {
					this.$toast(this.$t('toast13'))
				}
			}).catch(err => {
				this.$toast(err.message)
			})
		},
		async getAccount() {
			const poolabi = require('@/utils/pool.json');
			const egaabi = require('@/utils/ega.json');
			this.pool_instance = await new this.web3js.eth.Contract(poolabi, this.poolContract, {
				from: this.address,
			});
			this.ega_instance = await new this.web3js.eth.Contract(egaabi, this.egaContract, {
				from: this.address,
			});
			this.ega_instance.methods.balanceOf(this.address).call().then(v => {
				this.balance.real = v;
				this.balance.show = (BigNumber(Number(v)) / BigNumber(Math.pow(10, 18))).toFixed(4);
			});
			this.ega_instance.methods.destroy().call().then(v => {
				this.balance.destroy = (BigNumber(Number(v)) / BigNumber(Math.pow(10, 18))).toFixed(4);
			})
			http.get('/home/team', { address: this.address }).then(({ data }) => {
				if (data.errno == 200) {
					this.teamInfo = data.data;
					if (this.teamInfo.paddress) {
						this.bindShow = false;
						http.get('/home/info', { address: this.address }).then(({ data }) => {
							if (data.errno == 200) {
								this.totalInfo = data.data;
							}
						})
						http.get('/home/pool', { address: this.address }).then(({ data }) => {
							if (data.errno == 200) {
								this.poolInfo = data.data;
								// this.startCountdown();
							}
						})
						this.getList();
					}
				}
			})
		},
		// startCountdown() {
		// 	this.timer = setInterval(() => {
		// 		if (this.poolInfo.countdown_mining > 0) {
		// 			let formateLimitTimes = formateTimeStamp(this.poolInfo.countdown_mining);
		// 			let txt = '';
		// 			if (formateLimitTimes.day != 0) {
		// 				txt = `${formateLimitTimes.day} ${_this.$t('tian')} ${formateLimitTimes.hour} ${_this.$t('shi')} ${formateLimitTimes.min} ${_this.$t('fen')}   ${formateLimitTimes.seconds} ${_this.$t('miao')}`;
		// 			} else if (formateLimitTimes.hour != '00') {
		// 				txt = `${formateLimitTimes.hour} ${_this.$t('shi')} ${formateLimitTimes.min} ${_this.$t('fen')}  ${formateLimitTimes.seconds} ${_this.$t('miao')}`;
		// 			} else {
		// 				txt = `${formateLimitTimes.min} ${_this.$t('fen')}   ${formateLimitTimes.seconds} ${_this.$t('miao')}`;
		// 			}
		// 			this.timeStr = txt
		// 		} else {
		// 			clearInterval(this.timer);
		// 			this.timeStr = ''
		// 		}
		// 	}, 1000);
		// },
		getList() {
			this.list = [];
			if (this.tabActive == 0) {
				http.get('/my/custody', { address: this.address }).then(({ data }) => {
					if (data.errno == 200) {
						this.list = data.data;
					}
				})
			} else {
				http.get('/my/pool', { address: this.address, from: this.page.currentPage, amount: this.page.pageSize }).then(({ data }) => {
					if (data.errno == 200) {
						this.page.total = data.data.total;
						if (data.data.custodys != null) {
							this.list = data.data.custodys;
						} else {
							this.list = []
						}
					}
				})
			}
		},
		submitCleam() {
			this.$loading.show();
			http.post('/operate/principal', { address: this.address, mark: this.list[this.poolSelect].market }).then(({ data }) => {
				this.$loading.hide();
				if (data.errno == 200) {
					this.sendData(data.data);
				} else {
					this.$toast(data.errmsg);
				}
			}).catch(err => {
				this.$loading.hide();
			})
		},
		submitCleam1() {
			this.$loading.show();
			http.post('/operate/principal', { address: this.address, mark: 0 }).then(({ data }) => {
				this.$loading.hide();
				if (data.errno == 200) {
					this.sendData(data.data);
				} else {
					this.$toast(data.errmsg);
				}
			}).catch(err => {
				this.$loading.hide();
			})
		},
		submitTeamOut() {
			let balance = '';
			let api = '';
			if (!this.pledgeNum) return this.$toast(this.$t('alert4'));
			if (this.selectType == 1) {
				balance = this.totalInfo.extract_income;
				if (Number(this.pledgeNum) > Number(balance)) return this.$toast(this.$t('alert5'));
				api = '/operate/income';
			} else {
				balance = this.totalInfo.node_income;
				if (Number(this.pledgeNum) > Number(balance)) return this.$toast(this.$t('alert5'));
				api = '/operate/node';
			}
			this.$loading.show();
			http.post(api, { address: this.address, num: Number(this.pledgeNum) }).then(({ data }) => {
				this.$loading.hide();
				if (data.errno == 200) {
					this.sendData(data.data);
				} else {
					this.$toast(data.errmsg);
				}
			}).catch(err => {
				this.$loading.hide();
			})
		},
		submitBind() {
			if (!this.upAddress) return this.$toast(this.$t('alert3'));
			this.$loading.show();
			http.post('/operate/bind', { superior: this.upAddress, address: this.address }).then(({ data }) => {
				this.$loading.hide();
				if (data.errno == 200) {
					this.bindShow = false;
					this.getAccount();
				} else {
					this.$toast(data.errmsg);
				}
			}).catch(err => {
				this.$loading.hide();
			})
		},
		submitOpen() {
			if (!this.pledgeNum) return this.$toast(this.$t('alert4'));
			if (Number(((this.pledgeNum / this.totalInfo.price) + 1).toFixed(3)) > Number(this.totalInfo.extract_income)) return this.$toast(this.$t('alert5'));
			if (Number(this.pledgeNum) < 0.1 || (this.pledgeNum.substring(0, this.pledgeNum.indexOf('.') + 2)) != this.pledgeNum) return this.$toast(this.$t('add5'));
			this.$loading.show();
			http.post('/operate/open', { day: 30, amount: Number(this.pledgeNum), address: this.address }).then(({ data }) => {
				this.$loading.hide();
				if (data.errno == 200) {
					this.popupShow1 = false;
					this.sendData(data.data);
				} else {
					this.$toast(data.errmsg);
				}
			}).catch(err => {
				this.$loading.hide();
			})
		},
		submitOpen1() {
			if (!this.totalInfo.principal) return this.$toast(this.$t('alert4'));
			this.popupShow1 = false;
			this.$dialog.confirm({
				title: this.$t('add2'),
				message: this.$t('add2') + ':' + this.totalInfo.principal + 'EGA≈' + (this.totalInfo.principal * this.totalInfo.price).toFixed(3) + 'BNB',
				confirmButtonText: this.$t('confirm'),
				cancelButtonText: this.$t('cancel'),
			}).then(() => {
				this.$loading.show();
				http.post('/operate/principal_open', { day: 30, address: this.address }).then(({ data }) => {
					this.$loading.hide();
					if (data.errno == 200) {
						this.popupShow1 = false;
						this.sendData(data.data);
					} else {
						this.$toast(data.errmsg);
					}
				}).catch(err => {
					this.$loading.hide();
				})
			})
		},
		sendData(data) {
			this.$loading.show();
			this.web3js.eth.sendTransaction({
				from: this.address,
				to: this.poolContract,
				data: data
			}).then(res => {
				this.$loading.hide();
				this.getAccount();
				this.$toast(this.$t('toast10'));
			}).catch(err => {
				this.$loading.hide();
				this.$toast(err.message)
			})
		},
		submitBuyOther() {
			if (!this.pledgeNum) return this.$toast(this.$t('alert4'));
			if (Number(this.pledgeNum) > Number(this.balance.show)) return this.$toast(this.$t('alert5'));
			this.$loading.show();
			this.ega_instance.methods.allowance(this.address, this.poolContract).call().then(res => {
				if (res == 0) {
					this.ega_instance.methods.approve(this.poolContract, '115792089237316195423570985008687907853269984665640564039457584007913129639935').send().then(res => {
						this.transItOther();
					}).catch(err => {
						this.$loading.hide();
						this.$toast(err.message)
					})
				} else {
					this.transItOther();
				}
			})
		},
		submitBuy() {
			if (!this.pledgeNum) return this.$toast(this.$t('alert4'));
			if (Number(((this.pledgeNum / this.totalInfo.price) + 1).toFixed(3)) > Number(this.balance.show)) return this.$toast(this.$t('alert5'));
			if (Number(this.pledgeNum) < Number(this.poolInfo.min_num)) return this.$toast(this.$t('alert6') + this.poolInfo.min_num + 'BNB');
			this.$loading.show();
			this.ega_instance.methods.allowance(this.address, this.poolContract).call().then(res => {
				if (res == 0) {
					this.ega_instance.methods.approve(this.poolContract, '115792089237316195423570985008687907853269984665640564039457584007913129639935').send().then(res => {
						this.transIt();
					}).catch(err => {
						this.$loading.hide();
						this.$toast(err.message)
					})
				} else {
					this.transIt();
				}
			})
		},
		transIt() {
			this.pool_instance.methods._openAPond(30, BigNumber(Number(((this.pledgeNum / this.totalInfo.price) + 1).toFixed(3)) * Math.pow(10, 18)).toFixed(0)).send().then(res => {
				this.$loading.hide();
				this.popupShow1 = false;
				this.getAccount();
				this.$toast(this.$t('toast10'));
			}).catch(err => {
				this.$loading.hide();
				this.$toast(err.message)
			})
		},
		transItOther() {
			this.pool_instance.methods._openAPond(30, BigNumber(Number(this.pledgeNum) * Math.pow(10, 18)).toFixed(0)).send().then(res => {
				this.$loading.hide();
				this.popupShowOther = false;
				this.getAccount();
				this.$toast(this.$t('toast10'));
			}).catch(err => {
				this.$loading.hide();
				this.$toast(err.message)
			})
		},
		changeTab(i) {
			this.tabActive = i;
			this.list = [];
			this.page = {
				currentPage: 1,
				pageSize: 5,
				total: 0
			};
			this.getList();
		},
		allIn() {
			this.pledgeNum = this.balance.show;
		},
		allIn1() {
			this.pledgeNum = this.selectType == 1 ? this.totalInfo.extract_income : this.totalInfo.node_income;
		},
		handleCopy() {
			this.$toast(this.$t('copySuccess'));
		},
		async returnDay(time) {
			if (time == 0) {
				return 0
			} else {
				let nowTime = await this.pool_instance.methods.getTimestamp().call();
				let decTime = (Number(nowTime) - Number(time));
				// decTime/60/60/24
				let days = parseInt((decTime / 60 / 60 / 24).toString());
				return days
			}
		},
		returnBalance(val) {
			return parseFloat((Math.floor(val * 1000000) / 1000000).toFixed(6))
		},
		hideAddress(address) {
			if (address) {
				return address.substring(0, 6) + '...' + address.substring(address.length - 6, address.length)
			} else {
				return ''
			}
		},
		hideUrl(url) {
			if (url) {
				return url.substring(0, 20) + '...' + url.substring(url.length - 10, url.length)
			} else {
				return ''
			}
		},
		timestampToTime(timestamp) {
			var date = new Date(timestamp * 1000);
			var Y = date.getFullYear() + "-";
			var M =
				(date.getMonth() + 1 < 10
					? "0" + (date.getMonth() + 1)
					: date.getMonth() + 1) + "-";
			var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
			var h = date.getHours() + ":";
			var m = date.getMinutes();
			return Y + M + D + h + m;
		},
		numFormatter(num, digits = 2) {
			const si = [
				{ value: 1, symbol: "" },
				{ value: 1E3, symbol: "K" },
				{ value: 1E6, symbol: "M" },
				{ value: 1E9, symbol: "G" },
				{ value: 1E12, symbol: "T" },
				{ value: 1E15, symbol: "P" },
				{ value: 1E18, symbol: "E" }
			];
			const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
			let i;
			for (i = si.length - 1; i > 0; i--) {
				if (num >= si[i].value) {
					break;
				}
			}
			if (num < 1000) return num;
			return (BigNumber((num / si[i].value) * (Math.pow(10, digits))) / (Math.pow(10, digits))).toFixed(digits).replace(rx, " $1") + si[i].symbol;
		}
	}
};
</script>

<style lang="less" scoped>
.home-container {
	background: #151515;

	.top-content {
		padding: 0 20px;
		width: 100%;
		height: 180px;
		background-image: url('../image/topbg.png');
		background-size: 100% 100%;
		padding-top: 10px;
		display: flex;
		align-items: center;
		color: #000;

		.timedown {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 18px;
			color: #ff0000;
			font-weight: 600;

			.title {
				font-size: 20px;
			}
		}
	}

	.info {
		padding: 0 20px 30px;
		margin-top: -65px;

		&-total {

			&-item {
				width: 100%;
				height: 220px;
				background: #3B3F49;
				border-radius: 10px;
				padding: 5px 15px;

				&.other {
					height: 100px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 15px;

					.item {
						width: 50%;
					}
				}

				.item {
					height: 70px;
					display: flex;
					flex-direction: column;
					justify-content: center;

					&.top {
						display: flex;
						align-items: center;
						justify-content: space-between;
						flex-direction: row;

						.topItem {
							min-width: 33%;
							width: 50%;
						}
					}

					&.border {
						border-top: 1px solid #6b4e02;
					}

					.title {
						font-size: 12px;
						color: #8392a1;
						line-height: 2;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.value {
							font-size: 8px;
							font-weight: normal;
							color: #fff;
						}

						.van-icon {
							font-size: 20px;
							margin-right: 10px;
						}
					}

					.num {
						font-size: 16px;
						font-weight: bold;
						color: #fff;
						line-height: 1.5;

						.symple {
							font-size: 12px;
							color: #8392a1;
							font-weight: normal;
						}

						.value {
							font-size: 8px;
							font-weight: normal;
							color: #8392a1;
						}
					}

					.btn {
						width: 70px;
						height: 28px;
						border-radius: 14px;
						background: #ccec44;
						font-size: 11px;
						color: #000000;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}

		&-team {
			margin-top: 15px;
			width: 100%;
			height: 105px;
			border-radius: 10px;
			background: #3B3F49;
			position: relative;
			padding: 0 15px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			font-size: 12px;
			color: #ccec44;
			line-height: 1.8;

			.btn {
				color: #fff;
				align-items: center;
				font-size: 12px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.copy {
					color: #ccec44;
					border-bottom: 1px solid #ccec44;
					line-height: 1;
					position: relative;
					z-index: 1;
					margin-bottom: 5px;
				}
			}

			.btn1 {
				width: 100px;
				padding: 8px;
				border-radius: 14px;
				line-height: 1;
				background: #ccec44;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12px;
			}

			.coin {
				font-weight: bold;
			}

			.num {
				color: #8392a1;

				.value {
					color: #fff;
				}
			}

			.img {
				position: absolute;
				width: 110px;
				bottom: 0;
				right: 0;
			}
		}

		&-title {
			height: 50px;
			padding-top: 10px;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #fff;
			font-weight: bold;
		}

		&-one {
			width: 100%;
			height: 70px;
			border-radius: 10px;
			background: #3B3F49;
			display: flex;
			justify-content: space-between;
			padding: 0 15px;

			.title {
				font-size: 10px;
				color: #8abada;
			}

			.num {
				font-size: 18px;
				font-weight: bold;
				color: #fff;
				line-height: 1.5;

				.symple {
					font-size: 12px;
				}

				&.tipsnun {
					margin-top: 10px;
				}
			}

			.btn {
				width: 70px;
				height: 28px;
				border-radius: 14px;
				background-color: #ccec44;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 11px;
				color: #000000;
			}

			.item {
				width: 25%;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.tips {
				width: 70px;
				height: 17px;
				background-color: #f9d83d;
				border-radius: 0 6px 0 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #6b4e02;
				margin-top: -10px;
				margin-left: -20px;
			}
		}

		&-pool {
			width: 100%;
			border-radius: 10px;
			background-color: #3B3F49;
			margin-top: 20px;
			padding: 15px;
			box-shadow: 0 0 10px #b3d1fe;

			&-title {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-bottom: 10px;

				.name {
					display: flex;
					align-items: center;
					font-size: 14px;
					color: #fff;

					img {
						width: 24px;
						margin-right: 7.5px;
					}
				}

			}

			.tips {
				font-size: 14px;
				color: #aeb8c6;
				line-height: 1;
				padding: 10px 0;
				display: flex;
				align-items: center;
				gap: 10px;
				.nums {
					color: #fff;
				}
			}

			&-btns {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.btn {
					width: 70px;
					height: 28px;
					border-radius: 5px;
					background: #000000;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					color: #fff;

					&.active {
						border: 1px solid #ccec44;
						position: relative;
						color: #ccec44;

						img {
							position: absolute;
							width: 16px;
							top: -1px;
							right: -1px;
						}
					}
				}
			}

			&-submit {
				width: 305px;
				height: 37px;
				border-radius: 5px;
				background: #ccec44;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 13px;
				color: #000000;
				margin-top: 18px;
				&.notUse {
					background: #e9e9e9;
					color: #999;
				}
			}
		}

		&-title1 {
			height: 55px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			font-weight: bold;
			color: #ccd2da;
		}

		&-list {
			background: #3B3F49;
			border-radius: 10px;
			overflow: hidden;

			&-tab {
				width: 100%;
				height: 40px;
				display: flex;
				background: #55575b;

				&-item {
					height: 100%;
					width: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					color: #aeb8c6;

					&.active {
						border-radius: 10px 10px 0 0;
						background: #3B3F49;
						color: #fff;
					}
				}
			}

			&-btn {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 15px 25px;

				.btn {
					font-size: 11px;
					color: #aeb8c6;
					border: 1px solid #aeb8c6;
					width: 60px;
					height: 22px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 5px;

					&.active {
						color: #ccec44;
						border: 1px solid #ccec44;
					}
				}
			}

			&-item {
				margin: 0 20px;
				padding: 15px 0;

				&-title {
					height: 35px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 14px;
					font-weight: bold;
					color: #fff;
					padding-left: 20px;
					position: relative;

					&::before {
						content: '';
						width: 10px;
						height: 10px;
						border-radius: 5px;
						background: #ccec44;
						position: absolute;
						left: 0;
						top: 12.5px;
					}

					&.other {
						&::before {
							background: #636971;
						}
					}

					.time {
						font-size: 10px;
						color: #aeb8c6;
						font-weight: normal;
					}

					.close {
						font-size: 11px;
						color: #aeb8c6;
						line-height: 1;
						border-bottom: 1px solid #aeb8c6;
						font-weight: normal;

						&.other {
							border: none;
							color: #f9d83d;
						}
					}
				}

				&-time {
					padding: 0 15px;
					height: 20px;
					background: #f9d83d;
					border-radius: 10px;
					font-size: 10px;
					color: #6b4e02;
					display: inline-flex;
					align-items: center;
					justify-content: center;

					&.other {
						background: #626364;
						color: #c8d0db;
					}
				}

				&-info {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.infos {
						padding: 15px 0;

						&-item {
							display: flex;
							padding: 5px 0;

							.title {
								width: 95px;
								font-size: 11px;
								color: #aeb8c6;
							}

							.num {
								font-size: 12px;
								color: #fff;
							}
						}
					}

					.btn {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 80px;
						height: 28px;
						border-radius: 14px;
						background: #ccec44;
						font-size: 11px;
						color: #000000;

						&.other {
							background: #626364;
							color: #c8d0db;
						}
					}
				}
			}
		}
	}
}

.popup-content {
	width: 100%;
	height: 100%;
	padding: 20px;
	background: #3B3F49;

	&-name {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #fff;

		img {
			width: 24px;
			margin-right: 7.5px;
		}

		.tips {
			width: 70px;
			height: 17px;
			background-color: #ccec44;
			border-radius: 0 6px 0 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #6b4e02;
			font-size: 10px;
			margin-left: 15px;
		}
	}

	&-info {
		height: 90px;
		padding-top: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #e9edf4;

		&-item {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.title {
				font-size: 12px;
				color: #88919c;
				line-height: 2;
			}

			.num {
				font-size: 16px;
				font-weight: bold;
				color: #fff;

				.symple {
					font-size: 12px;
					color: #88919c;
					font-weight: normal;
				}
			}
		}
	}

	&-balance {
		height: 40px;
		display: flex;
		align-items: center;
		font-size: 12px;
		color: #88919c;

		.num {
			color: #fff;
		}

		&.other {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&.rightInfo {
			justify-content: flex-end;
			height: 15px;
		}
	}

	&-title {
		height: 40px;
		display: flex;
		align-items: center;
		font-size: 12px;
		color: #88919c;

		&.other {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	&-submit {
		width: 100%;
		height: 44px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 13px;
		color: #000000;
		background: #ccec44;
		margin-top: 25px;
	}

	&-submits {
		margin-top: 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.btnBig {
			width: 100%;
			height: 44px;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 13px;
			color: #000000;
			background: #ccec44;
		}

		.btn {
			width: 150px;
			height: 44px;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 13px;
			color: #000000;
			background: #ccec44;

			&.active {
				color: #000;
				background: #ffffff;
				border: 1px solid #000;
			}
		}
	}

	&-tab {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&-item {
			width: 76px;
			height: 72px;
			background: #000;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			.title {
				font-size: 14px;
				color: #fff;
				line-height: 2;
			}

			.num {
				font-size: 12px;
				color: #aeb8c6;
				font-weight: bold;
			}

			&.active {
				position: relative;

				img {
					position: absolute;
					top: -2px;
					right: -2px;
				}

				.title {
					color: #ccec44;
				}

				.num {
					color: #ccec44;
				}

				border: 1px solid #ccec44;

			}
		}
	}

	&-total {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px 0;
		border-bottom: 1px solid #e9edf4;

		&-item {
			width: 70px;

			.title {
				font-size: 12px;
				color: #88919c;
			}

			.num {
				font-size: 14px;
				font-weight: bold;
				color: #fff;

				.symple {
					font-size: 12px;
					color: #88919c;
					font-weight: normal;
				}
			}
		}

		&-progress {
			width: 180px;
		}
	}

	&-inputs {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 44px;
		border-radius: 5px;
		padding: 0 10px;
		background: #f0f3f7;

		input {
			width: 230px;
			font-size: 14px;
			color: #000;
		}

		.symple {
			font-size: 14px;
			color: #88919c;
		}

		.allin {
			font-size: 12px;
			color: #0167ff;
			font-weight: bold;
		}
	}

	&-out {
		display: flex;
		justify-content: center;
		padding: 20px 0;
		border-bottom: 1px solid #e9edf4;

		.income {
			text-align: center;

			.title {
				font-size: 12px;
				color: #8392a1;
				line-height: 2;
			}

			.num {
				font-size: 18px;
				font-weight: bold;
				color: #151616;
				line-height: 1.5;
			}
		}
	}

	&-income {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding: 20px 0;
		border-bottom: 1px solid #e9edf4;

		.income {
			.title {
				font-size: 12px;
				color: #fff;
				line-height: 2;
			}

			.num {
				font-size: 18px;
				font-weight: bold;
				color: #151616;
				line-height: 1.5;

				.symple {
					font-size: 14px;
					color: #8392a1;
				}
			}
		}

		.time {
			padding: 0 15px;
			height: 20px;
			background: #f9d83d;
			border-radius: 10px;
			font-size: 10px;
			color: #6b4e02;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}
	}

	&-tips {
		height: 28px;
		display: flex;
		align-items: center;
		font-size: 11px;
		color: #0167ff;
		background: linear-gradient(to right, #e5f0ff, #fff);
	}
}

.popup-bind {
	width: 100%;
	padding: 20px;
	padding-bottom: 30px;
	background: #3B3F49;

	&-title {
		font-size: 18px;
		color: #fff;
		font-weight: bold;
		line-height: 40px;
		text-align: center;
	}

	&-input {
		width: 100%;
		height: 44px;
		border-radius: 5px;
		border: 1px solid #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 10px;
		margin-top: 20px;

		input {
			width: 100%;
			font-size: 12px;
			color: #fff;
		}
	}

	&-btn {
		width: 100%;
		height: 44px;
		border-radius: 5px;
		background: #ccec44;
		font-size: 13px;
		color: #000;
		margin-top: 20px;
	}
}

.no-data {
	display: flex;
	justify-content: center;
	padding: 80px 0;
	color: #aeb8c6;
	font-size: 11px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	img {
		width: 50px;
		margin-bottom: 15px;
	}
}

.popover-content {
	padding: 5px 15px;

	.popover-title {
		width: 100px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 40px;
		font-size: 12px;
		color: #c6c6c6;
		display: flex;
	}
}
</style>